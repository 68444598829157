import React from 'react';
import './App.css';

const App = () => {
  return (
    <div>Arcbrick Constructions. Comming soon...</div>
  )
}

export default App;
